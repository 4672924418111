import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';

export const offerQuery = graphql`
  query offerPageQuery($uid: String) {
    prismic {
      allLending_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            meta_title
            meta_keywords
            meta_description
            title
            content
            hero
          }
        }
      }
    }
  }
`

const RenderBody = ({ offerPage }) => {
  return (
    <React.Fragment>
      {offerPage.hero && (
        <picture className='hero'>
          <source srcset={offerPage.hero.Mobile.url} media='(max-width: 768px)' className='img-responsive' />
          <img src={offerPage.hero.url} loading='lazy' alt={offerPage.hero.alt} className='img-responsive' />
          {offerPage.title && (<div className='hero-text'><h1>{offerPage.title}</h1></div>)}
        </picture>
      )}
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1 mt-4'>
            {offerPage.hero ? null : <h1>{offerPage.title}</h1>}
            {RichText.render(offerPage.content, linkResolver)}
            <form
              name='Offer'
              method='POST'
              action='/success'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
            >
              <input type='hidden' name='bot-field' />
              <input type='hidden' name='form-name' value='Offer' />
              <div className='form-group col-md-6'>
                <label className='mt-1' htmlFor='name'>Name<sup className='text-green'>*</sup></label>
                <input className='form-control input-lg' type='text' name='name' id='name' required />
              </div>
              <div className='form-group col-md-6'>
                <label className='mt-1' htmlFor='email'>Email<sup className='text-green'>*</sup></label>
                <input className='form-control input-lg' type='email' name='email' id='email' required />
              </div>
              <div className='form-group col-md-6'>
                <label className='mt-1' htmlFor='phone'>Phone</label>
                <input className='form-control input-lg' type='tel' name='phone' id='phone' />
              </div>
              <div className='form-group col-md-6'>
                <label className='mt-1' htmlFor='offer'>Offer Amount<sup className='text-green'>*</sup></label>
                <div className='input-group'>
                  <span className='input-group-addon'>$</span>
                  <input className='form-control input-lg' name='offer' id='offer' type='number' required />
                  <span className='input-group-addon'>.00</span>
                </div>
              </div>
              <div className='form-group col-md-12'>
                <label className='mt-1' htmlFor='property'>Subject Property<sup className='text-green'>*</sup></label>
                <textarea className='form-control input-lg' name='property' id='property' required></textarea>
              </div>
              <div className='form-group col-md-12'>
                <label className='mt-1' htmlFor='message'>Message<sup className='text-green'>*</sup></label>
                <textarea className='form-control input-lg' name='message' id='message' required></textarea>
              </div>
              <div className='form-group col-md-12'>
                <button className='btn btn-primary btn-block btn-lg' type='submit'>Send</button>
              </div>
              <p><strong>Seller Preferred:</strong> Buyer agrees to use the Seller's preferred title partner and closing agent.</p>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const OfferPage = props => {
  const doc = props.data.prismic.allLending_pages.edges.slice(0,1).pop();
  if(!doc) return null;

  return (
    <Layout>
      <SEO title={doc.node.meta_title} keywords={doc.node.meta_keywords} description={doc.node.meta_description} lang='en-us' />
      <RenderBody offerPage={doc.node} />
    </Layout>
  )
}
export default OfferPage
